import React from "react"
import "twin.macro"

export const ChainIcon = () => {
  return (
    <svg
      width="91"
      height="91"
      xmlns="http://www.w3.org/2000/svg"
      tw="transition ease-linear duration-100"
    >
      <g clipPath="url(#clip0)" tw="fill-current">
        <path d="M62.226 39.598a8 8 0 110 11.313 8.01 8.01 0 010-11.313zm7.542 7.542a2.666 2.666 0 10-3.77-3.77 2.666 2.666 0 003.77 3.77z" />
        <path d="M26.333 64.177c-1.64-1.64-2.683-1.785-4.41-2.023-2.597-.358-5.828-.804-10.61-5.586a16.018 16.018 0 010-22.627c4.782-4.781 8.013-5.227 10.61-5.586 1.727-.238 2.77-.381 4.41-2.022 1.64-1.64 1.784-2.684 2.023-4.41.358-2.597.804-5.828 5.585-10.61A16 16 0 0156.57 33.942c-4.782 4.781-8.013 5.227-10.61 5.586-1.726.238-2.769.382-4.41 2.022-1.466 1.466-1.736 2.456-1.948 3.882l.054-.01c.18 1.08.447 2.092 1.894 3.539 1.405 1.405 2.372 1.712 3.706 1.92 1.32-.204 2.286-.5 3.705-1.92 1.641-1.64 1.785-2.683 2.023-4.41.358-2.596.804-5.828 5.586-10.609a16 16 0 1122.627 22.627c-4.781 4.782-8.013 5.228-10.609 5.586-1.727.238-2.77.382-4.41 2.023-1.64 1.64-1.785 2.683-2.023 4.41-.358 2.596-.804 5.828-5.585 10.609a16.018 16.018 0 01-22.628 0c-4.781-4.781-5.227-8.013-5.586-10.609-.238-1.727-.382-2.77-2.022-4.41zM45.23 34.243c2.084-.288 4.052-.559 7.566-4.073a10.667 10.667 0 00-15.085-15.085c-3.514 3.514-3.785 5.483-4.073 7.567-.294 2.13-.627 4.544-3.535 7.452-2.907 2.908-5.322 3.241-7.452 3.535-2.084.288-4.053.56-7.567 4.073A10.666 10.666 0 1030.17 52.797c3.514-3.514 3.786-5.482 4.073-7.566.294-2.13.627-4.545 3.535-7.453 2.908-2.907 5.322-3.24 7.453-3.535zM67.858 56.87c2.084-.287 4.053-.559 7.567-4.073A10.667 10.667 0 1060.34 37.712c-3.514 3.514-3.786 5.483-4.073 7.567-.295 2.13-.628 4.545-3.535 7.452-.135.135-.268.26-.4.384a23.744 23.744 0 014.237 3.453 15.929 15.929 0 013.18 4.539c.205-.231.418-.465.656-.702 2.908-2.907 5.323-3.24 7.453-3.535zm-15.06 18.555a10.679 10.679 0 000-15.085c-3.515-3.515-5.484-3.786-7.567-4.074-2.132-.293-4.545-.627-7.453-3.535-.134-.134-.26-.266-.384-.398a23.74 23.74 0 01-3.453 4.236 15.928 15.928 0 01-4.538 3.18c.23.205.465.418.702.656 2.908 2.907 3.241 5.322 3.535 7.452.287 2.085.558 4.053 4.072 7.568a10.679 10.679 0 0015.085 0z" />
        <path d="M39.598 62.225a8 8 0 110 11.314 8.009 8.009 0 010-11.314zm7.543 7.543a2.667 2.667 0 10-3.772-3.772 2.667 2.667 0 003.772 3.772zM39.598 16.97a8 8 0 110 11.314 8.009 8.009 0 010-11.314zm7.543 7.543a2.667 2.667 0 10-3.772-3.772 2.667 2.667 0 003.772 3.772zM28.284 50.911a8 8 0 110-11.313 8.009 8.009 0 010 11.313zM20.74 43.37a2.667 2.667 0 103.773 3.772 2.667 2.667 0 00-3.773-3.772z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            transform="scale(-1 1) rotate(45 -22.627 -54.627)"
            d="M0 0h64v64H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
